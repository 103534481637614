<template>
  <div class="crm-tool">
    <a-dropdown>
      <a @click="(e) => e.preventDefault()">
        <i class="iconfont icon-table-"></i>
        <span>{{ $t(mCurrentView) }}</span>
        <a-icon type="down" />
      </a>
      <a-menu slot="overlay" @click="handleViewChange">
        <a-menu-item v-for="m in menus" :key="m">
          <i class="iconfont icon-table-"></i>
          {{ $t(m) }}
        </a-menu-item>
        <!-- <a-menu-item key="cardView">
          <i class="iconfont icon-table-" />
          {{ $t("cardView") }}
        </a-menu-item> -->
      </a-menu>
    </a-dropdown>
    <div class="btn-group">
      <slot name="other"></slot>
      <!-- <div class="search-wrap" v-click-outside="() => (show = false)">
        <n-button icon="icon-sousuo1" v-show="!show" @click="handleClick">
          {{ $t("search") }}
        </n-button>
        <a-input
          ref="refInput"
          v-show="show"
          :placeholder="$t('typeContent')"
          autoFocus
          v-model="input"
          @pressEnter="handleEnter"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
      </div> -->
      <!-- <n-button icon="icon-shenglvehao"></n-button> -->
      <slot name="create">
        <a-button class="plus" icon="plus" @click="handleCreate" type="primary">
          {{ $t("create") }}
        </a-button>
      </slot>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations } = createNamespacedHelpers("jiyang/app");
import NButton from "./button/index.vue";
export default {
  components: {
    NButton,
  },
  props: {
    current: {
      type: String,
      default: "tableView",
    },
    label: {
      type: String,
      default: "create",
    },
    menus: {
      type: Array,
      default: () => ["tableView", "cardView"],
    },
  },
  data() {
    return {
      input: "",
      show: false,
      mCurrentView: this.current,
    };
  },
  computed: {
    ...mapState(["navCollapse"]),
  },
  watch: {
    current(val) {
      this.mCurrentView = val;
    },
  },
  methods: {
    ...mapMutations(["setCollapse"]),
    handleClick() {
      this.show = true;
      this.$nextTick(() => {
        this.$refs.refInput.focus();
      });
    },
    handleEnter() {
      this.$emit("search", this.input);
    },
    handleCreate() {
      this.mCurrentView = "createView";
      this.setCollapse(true);
      this.emit();
    },
    handleViewChange({ key }) {
      this.mCurrentView = key;
      this.emit();
    },
    emit() {
      this.$emit("change-view", this.mCurrentView);
    },
  },
};
</script>

<style lang="scss">
.crm-tool {
  padding: 0 100px;
  font-size: $head-font-size;
  // height: $tool-bar-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 15px;
  position: relative;
  color: #7f7e79;
  min-width: 690px;
  margin-bottom: 15px;
  .icon-table- {
    font-size: $base-font-size;
  }
  a {
    font-size: $head-font-size;
    font-weight: 700;
    color: #3a3b3d;
  }
  .btn-group {
    display: flex;
    .ant-input {
      border: none;
      background-color: transparent;
    }
    .search-wrap {
      display: flex;
      align-items: center;
    }
    .ant-input:focus {
      box-shadow: none;
    }
  }
}
</style>
